// export const SERVER_URL = 'http://localhost:4000';

// DEV
// export const SERVER_URL = 'http://18.117.238.157:4000/' 

// dev server domain
export const SERVER_URL = 'https://dev-backend.tars.pro'
// export const SERVER_URL = 'https://backend.tars.pro'
// export const SERVER_URL = 'https://backend.tars.gg'
// export const SERVER_URL = 'http://192.168.18.11'
